import {
  dashboardReducer,
  dashboardTypesReducer,
  datasetSelectorReducer,
  eventSummaryReducer,
  matchEventsReducer,
} from './dashboard';
import { playlistDownloadReducer, playlistReducer, userPlaylistReducer } from './playlist';
import { translationReducer } from './translation';
import { userReducer } from './user';

export const reducers = {
  dashboard: dashboardReducer,
  dashboardTypes: dashboardTypesReducer,
  translation: translationReducer,
  matchEvents: matchEventsReducer,
  eventSummary: eventSummaryReducer,
  datasetSelectors: datasetSelectorReducer,
  playlist: playlistReducer,
  userPlaylist: userPlaylistReducer,
  user: userReducer,
  playlistDownload: playlistDownloadReducer,
  // Add more reducers here
};
