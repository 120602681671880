import {
  Component,
  forwardRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { buildUniqueComponentId } from 'src/app/shared/utils';

@Component({
  selector: 'spaui-badge',
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BadgeComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    id: buildUniqueComponentId('spaui-badge'),
  },
})
export class BadgeComponent {
  @Input() label: string | undefined | number;
  @Input() type: 'primary' | 'success' | 'warning' | 'danger' | 'secondary' = 'success';
  @Input() badgeType: 'badge' | 'pill' = 'badge';
}
